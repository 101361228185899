import { IAlbum } from "../../common/Album";

const album: IAlbum = {
  title: "The Standard",
  date: new Date(2025, 0, 26),
  img: "/img/train.jpeg",
  tracks: [
    {
      title: "Silky Smooth",
      src: "/songs/2025-01-26/silky_smooth.mp3",
      duration: 769.2,
    },
    {
      title: "Escape Hatch",
      src: "/songs/2025-01-26/escape_hatch.mp3",
      duration: 889.344,
    },
    {
      title: "Droppin' Dimes",
      src: "/songs/2025-01-26/droppin_dimes.mp3",
      duration: 1040.28,
    },
    {
      title: "Sharing Is Caring",
      src: "/songs/2025-01-26/sharing_is_caring.mp3",
      duration: 612.72,
    },
    {
      title: "True To Form",
      src: "/songs/2025-01-26/true_to_form.mp3",
      duration: 678.696,
    },
    {
      title: "Rockin' Lullaby",
      src: "/songs/2025-01-26/rockin_lullaby.mp3",
      duration: 941.328,
    },
    {
      title: "Train Hoppin' Part 1",
      src: "/songs/2025-01-26/train_hoppin_part_one.mp3",
      duration: 690.624,
    },
    {
      title: "Train Hoppin' Part 2",
      src: "/songs/2025-01-26/train_hoppin_part_two.mp3",
      duration: 1224.048,
    },
    {
      title: "Espionage Season",
      src: "/songs/2025-01-26/espionage_season.mp3",
      duration: 1147.68,
    },
    {
      title: "Unburden Yourself",
      src: "/songs/2025-01-26/unburden_yourself.mp3",
      duration: 1519.368,
    },
    {
      title: "Cotton Candy",
      src: "/songs/2025-01-26/cotton_candy.mp3",
      duration: 1768.2,
    },
    {
      title: "Uncertain Times",
      src: "/songs/2025-01-26/uncertain_times.mp3",
      duration: 820.104,
    },
    {
      title: "Dual Purpose Mode",
      src: "/songs/2025-01-26/dual_purpose_mode.mp3",
      duration: 657.096,
    },
    {
      title: "Expanding Universe",
      src: "/songs/2025-01-26/expanding_universe.mp3",
      duration: 463.776,
    },
  ],
};
export default album;
